<template>
  <QuotaSum />
</template>

<script>
export default {
  name: "QuotaSumPage",
  components: {
    QuotaSum: () =>
      import("~cp/Quota/QuotaSum/QuotaSum"),
  },
};
</script>

<style>
</style>